.ems-brand {
  position: relative;
  height: 50px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .ems-brand-soon {
    position: relative;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    line-height: 50px;
    text-align: center;
  }
}
