.plans-container {
  margin: 80px 0;

  .plans-header {
    margin-bottom: 40px;

    .title {
      font-weight: 600;
      font-size: 2rem;
    }

    .description {
      font-weight: light;
      font-size: 1rem;
      font-style: italic;
    }
  }
}

.plans {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
}

.plan-item {
  background-color: white;
  padding: 16px;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .title {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0;
  }

  .price {
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
  }

  .savings {
    font-weight: 700;
    font-size: 16px;
    background-color: #ff5000;
    color: white;
    padding: 4px 12px;
    width: fit-content;
    margin: 0 auto;
    border-radius: 16px;
  }

  .usps {
    list-style: none;
    padding: 0;
    text-align: center;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      display: flex;
      gap: 8px;
      align-items: center;

      p {
        margin: 0;
      }

      .check-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .plans {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .plans {
    grid-template-columns: 1fr;
  }
}
