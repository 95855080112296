.cta-container {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 20px;
  margin: 40px 0;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 40px 40px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #d9d9d9;

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    padding: 20px 0;

    .title {
      font-size: 32px;
      font-weight: 700;
      color: #000;
      width: fit-content;
    }
  }

  .image-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .cover {
      object-fit: cover;
    }

    .contain {
      object-fit: contain;
    }
  }
}

@media (max-width: 768px) {
  .cta-container {
    display: flex;
    flex-direction: column;
  }

  .image-container {
    display: none;
  }
}
