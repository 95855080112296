.mid-calltoaction {
  @extend .bg-white;
  @extend .text-body;

  padding: 2.5rem;

  text-shadow: none;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 35px;
  margin-bottom: 35px;

  h2 {
    margin: 0;
    font-size: 1.5rem;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    .btn {
      margin-top: 20px;
    }
    h2 {
      text-align: center;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: 1.5rem;
    margin-top: 25px;
    margin-bottom: 25px;
    h2 {
      font-size: 1.3rem;
    }
  }
}
