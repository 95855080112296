.product-showcase-container {
  .title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 32px;
  }
  .product-showcase-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 52px;
    gap: 80px;

    @media (max-width: 768px) {
      gap: 40px;
      flex-direction: column;
    }

    @media (max-width: 480px) {
      gap: 20px;
    }

    .product {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      overflow: hidden;
      padding: 32px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 400px;

      .content {
        z-index: 2;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .description {
        display: none;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover .description {
        display: block;
        opacity: 1;
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
        transition: background-color 0.3s ease-in-out;
      }

      &:hover::before {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}
