.step-container {
  position: relative;
  list-style: none;
  padding: 0;
}

.step-container .step {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 2rem;
}

.step-container .step::before {
  content: "";
  position: absolute;
  top: 0;
  left: 2rem;
  height: 100%;
  width: 2px;
  background-color: #ff5000;
}

.step-container .step:last-child::before {
  display: none;
}

.step-container .step .circle {
  position: relative;
  width: 4rem;
  height: 4rem;
  background-color: #ff5000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
}

.circle-text {
  font-size: 1.5rem;
  font-weight: 500;
  color: #ffffff;
}

.step-container .step h3 {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
