.support-widget-link {
  img {
    opacity: 1;
    transition: opacity .15s ease-in-out;
  }
  &:hover img {
    opacity: 0.5;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}
