$gradient-color1: rgba(0, 113, 225, 0.9);
$gradient-color2: rgba(0, 196, 255, 0.9);

$gridsense-background-darken: 35%;
$gradient-color1: darken($gradient-color1, $gridsense-background-darken);
$gradient-color2: darken($gradient-color2, $gridsense-background-darken);

.background-gridsense {
  background-image: linear-gradient(to bottom right, $gradient-color1, $gradient-color2), url(./../img/solar-banner.jpg);
  background-position: center;
  background-size: cover;
}
