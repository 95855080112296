.home-banner {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: 600px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    z-index: 1;
    padding: 10px;

    .content-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @media screen and (max-width: 768px) {
      .content-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .title-container {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .title {
      font-size: 3.25rem;
      font-weight: bold;
    }

    .subtitle {
      font-size: 1.5rem;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-top: 200px;
    }
  }

  .scroll-down-indicator {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    color: white;
    animation: bounce 2s infinite;
    z-index: 10;
  }

  @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
          transform: translateY(0) translateX(-50%);
      }
      40% {
          transform: translateY(-10px) translateX(-50%);
      }
      60% {
          transform: translateY(-5px) translateX(-50%);
      }
  }
}

// Add black overlay
.home-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

// Add orange gradient
.home-banner::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(255, 80, 0, 0.8) 0%, /* #FF5000 at 0% with 80% opacity (bottom) */ 
    rgba(255, 80, 0, 0.5) 45%, /* #FF5000 at 55% with 50% opacity */ 
    rgba(255, 80, 0, 0) 100% /* #FF5000 at 100% with 0% opacity (top) */
  );
  z-index: 1;
}
