.sub-banner {
  position: relative;
  width: 100%;
  height: 50vh;
  min-height: 600px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .titleContainer {
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    color: white;
    text-align: center;

    .title {
      font-size: 52px;
      font-weight: bold;
    }

    .subtitle {
      font-size: 24px;
    }
  }
}

// Add black overlay
.sub-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

// Add orange gradient
.sub-banner::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top,
      rgba(255, 80, 0, 0.8) 0%,
      /* #FF5000 at 0% with 80% opacity (bottom) */
      rgba(255, 80, 0, 0.5) 45%,
      /* #FF5000 at 55% with 50% opacity */
      rgba(255, 80, 0, 0) 100%
      /* #FF5000 at 100% with 0% opacity (top) */
    );
  z-index: 1;
}

.sub-banner-blue {
  position: relative;
  width: 100%;
  height: 50vh;
  min-height: 600px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .titleContainer {
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    color: white;
    text-align: center;

    .title {
      font-size: 52px;
      font-weight: bold;
    }

    .subtitle {
      font-size: 24px;
    }
  }
}

// Add black overlay
.sub-banner-blue::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

// Add orange gradient
.sub-banner-blue::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top,
      rgba(0, 113, 225, 0.8) 0%,
      rgba(0, 113, 225, 0.5) 45%,
      rgba(255, 80, 0, 0) 100%);
  z-index: 1;
}
