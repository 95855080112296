.we-moved-banner {
  position: relative;
  width: 100%;

  .banner {
    width: 100%;
    background-color: #FF5000;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    pointer-events: pointer;
    text-align: center;

    p {
      font-size: 1.5rem;
      margin: 0;
    }
  }
}
