.container {
  &.container-sm {
    max-width: 720px;
  }
}

.article-open {
  p {
    font-size: 1.2rem;
  }
}

.card-rounded {
  @extend .bg-white;
  padding: 3rem;
  border-radius: 10px;

  p:last-child {
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(sm) {
  .card-rounded {
    padding: 1.5rem;
  }
}

.actie-header {
  @extend .slogan;
  font-size: 2.5rem;
}

@include media-breakpoint-down(sm) {
  .actie-header {
    font-size: 2rem;
  }
}

.properties-container {
  margin: 4rem 0;

  h2,
  .description {
    text-align: center;
  }

  .properties-grid {
    text-align: left;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    column-gap: 4rem;

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 768px) {
    .properties-grid {
      grid-template-columns: 1fr;
    }
  }
}
