.nav-link.active {
  font-weight: bold;
  color: #ff5000 !important;
}
.nav-link.black-friday {
  font-weight: bold;
}
.navbar-light {
  .nav-link.black-friday {
    color: black;
  }
}
.navbar-dark {
  .nav-link.black-friday {
    color: white;
  }
}

.navbar-second.bg-dark {
  border-bottom: solid #4c535b 1px;
}

.navbar-only,
.navbar-main {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
