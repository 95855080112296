.reviews-container {
  margin: 80px 0;
  a {
    text-decoration: none;
    color: black !important;
  }

  .reviews-header {
    margin-bottom: 40px;

    .title {
      font-weight: 600;
      font-size: 2rem;
    }

    .description {
      font-weight: light;
      font-size: 1rem;
      font-style: italic;
    }
  }
}

.reviews {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.review-item {
  background-color: white;
  padding: 32px;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;

  .quote {
    width: 32px;
    height: 32px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .title {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0;
  }
  .description {
    font-size: 1.25rem;
    margin: 0;
  }

  .source {
    font-size: 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    align-items: center;
    margin-top: 24px;

    img {
      width: 24px;
      height: 24px;
    }

    p {
      margin: 0;
    }
  }

  .stars {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin: 24px 0;

    .star-icon {
      color: #ffc400;
    }
  }
}

@media (max-width: 1200px) {
  .reviews {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .reviews {
    grid-template-columns: 1fr;
  }
}
