.offerte-widget {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .offerte {
    min-width: 350px;
    max-width: 420px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .offerte-header {
    padding: 20px 20px 0 20px;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    background-color: #f5f5f5;
    border-radius: 16px 16px 0 0;
    width: 100%;

    .offerte-button {
      border: none;
      padding-top: 10px;
      font-size: 1rem;
      font-weight: 600;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 12px;
    }
    .active {
      border-bottom: #ff5000 3px solid;
      width: 100%;
      height: 1px;
    }
  }

  .offerte-content {
    padding: 0 12px;
    
    .offerte-content-usp {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      
      .usp {
        display: flex;
        gap: 12px;
        align-items: center;
      }
    }

    .form-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }
}
