.overzicht-cta {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;

  .title {
    font-size: 32px;
    font-weight: 600;
  }

  .subtitle {
    font-size: 20px;
    font-weight: light;
  }

  .button {
    padding: 12px 20px;
    background-color: #ff5000;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    font-size: 20px;
  }
}
