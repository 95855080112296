.info-night-widget {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .info-night {
    min-width: 350px;
    max-width: 420px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 12px;
  }

  .info-night-header {
    padding: 15px 15px 0 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #f5f5f5;
    border-radius: 16px 16px 0 0;
    width: 100%;

    h2 {
      margin: 0;
      padding: 0 0 10px 0;
      font-size: 2rem;
    }
  }

  .info-night-content {
    padding: 0 20px;
    width: 100%;
    
    .info-night-usp {
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      
      .usp {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    
    .form-container-col {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    
    .form-group {
      margin-bottom: 8px;
      
      label {
        margin-bottom: 2px;
        font-size: 0.8rem;
      }
      
      .form-control {
        padding: 6px 8px;
        height: auto;
      }
    }
    
    button.btn {
      margin-top: 5px;
      padding: 8px 0;
    }
    
    small.form-text {
      font-size: 0.75rem;
      margin-top: 4px;
    }

    .alert {
      padding: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
