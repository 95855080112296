@use "sass:math";

.black-friday-calltoaction {
  @extend .card-rounded;

  display: grid;

  padding: 0 !important;

  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  img {
    width: 100%;
    grid-column: 1;
    grid-row: 1;

    border-radius: 10px;
  }

  .bf-body {
    @extend .text-right;
    @extend .text-white;
    @extend .roboto;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    grid-column: 1;
    grid-row: 1;

    padding-right: 3rem;

    font-size: 2rem;
  }
  @include media-breakpoint-down(md) {
    .bf-body {
      padding-right: 2rem;
      font-size: 1.5rem;
    }
  }
  @include media-breakpoint-down(xs) {
    .bf-body {
      padding-right: 1.2rem;
      font-size: 1.1rem;
      line-height: 1.2rem;
    }
  }
}
