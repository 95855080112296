.image-text-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 40px 0;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding: 40px 0;

    .title {
      font-size: 2rem;
      font-weight: 700;
      padding-bottom: 8px;
      width: fit-content;
    }
  }

  .image-container {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 20px;
    position: relative;
    flex-direction: column;
    align-items: center;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .multiple-image-container {
      position: absolute;
      width: 100%;
      max-width: 75%;
      height: 100%;
      display: flex;
      gap: 20px;
      flex-direction: column;
      justify-content: space-between;
      
      .img-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
      }
    }

    .cover {
      object-fit: cover;
    }

    .contain {
      object-fit: contain;
    }

    .overflow-box {
      overflow: visible;
    }
  }
}

.reverse {
  .text-container {
    order: 2;
  }
}

.title-border {
  width: 100%;
  border-bottom: 4px solid #ff5000;
}

@media (max-width: 768px) {
  .image-text-container {
    grid-template-columns: 1fr;

    .text-container {
      order: 1;
    }

    .image-container {
      order: 2;
      position: relative;
      height: 100%;
      width: 100%;
      justify-content: center;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 400px;
      }
    }
  }
}

.ramik {
  min-height: 400px;
}
