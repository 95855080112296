.navbar-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  .hidden {
    display: none;
  }
}

.navbar-scrolled {
  background-color: white;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .navbar-brand {
    width: 240px;
    transition: width 0.3s ease;
  }
}

.navbar-not-scrolled {
  backdrop-filter: blur(2px);
  background: linear-gradient(to bottom, rgb(0, 0, 0, 0.5), transparent);
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;

  .navbar-brand {
    width: 300px;
    transition: width 0.3s ease;
  }
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 24px;
}

.navbar-brand {
  width: 300px;
  height: 100%;
}

.hamburger-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 24px;
}

.mobile-menu {
  margin: 0;

  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  padding: 0 24px;
  margin-top: 0px;
  list-style: none;
  z-index: 999;
  font-weight: 500;
  height: 0px;
  opacity: 0;
  transition: height 0.3s ease, opacity 0.1s ease, margin-top 0.3s ease;

  li {
    display: none;
  }
}
.mobile-menu.open {
  display: flex;
  height: 200px;
  margin: 12px 0 24px 0;
  opacity: 1;
  transition: height 0.3s ease, opacity 0.8s ease, margin-top 0.3s ease;

  li {
    display: block;
  }
}

.hamburger-icon {
  font-size: 30px;
  cursor: pointer;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  font-size: 18px;

  a {
    color: #fff;
    text-decoration: none;
  }
}

.sub-navbar {
  position: sticky;
  top: 0px;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  border-top: 1px solid lightgray;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .subbar {
    font-size: 16px;
    overflow-x: auto;
    white-space: nowrap;
    padding: 12px 24px;
  }
}

@media (min-height: 500px) {
  .navbar-container {
    position: fixed;
  }
  .sub-navbar {
    top: 94px;
  }
}
