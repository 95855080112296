.jumbotron:has(.container):has(.jumbotron-calltoaction) {
  margin-bottom: 75px;
}
@include media-breakpoint-down(xs) {
  .jumbotron:has(.container):has(.jumbotron-calltoaction) {
    margin-bottom: 95px;
  }
}

.jumbotron-calltoaction {
  @extend .bg-white;
  @extend .text-body;

  padding: 3rem;

  text-shadow: none;
  border-radius: 10px;

  display: flex;
  flex-direction: row;

  margin-top: 50px;
  margin-bottom: -100px;

  h2 {
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    .btn {
      margin-top: 20px;
    }
    h2 {
      text-align: center;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: 1.5rem;
    h2 {
      font-size: 1.3rem;
    }
  }
}
