.actie-banner {
  .title {
    @extend .roboto;
    font-size: 6rem;
  }
  .circle {
    width: 400px;
    transform: translateY(25%);
  }
  .gradient {
    background: rgb(255,80,0);
    background: linear-gradient(180deg, rgba(255,80,0,0) 41%, rgba(255,80,0,0.75) 100%);
  }
}

@include media-breakpoint-down(lg) {
  .actie-banner {
    .title {
      font-size: 4.5rem;
    }
    .circle {
      width: 275px;
    }
  }
}
@include media-breakpoint-down(sm) {
  .actie-banner {
    .title {
      font-size: 3rem;
    }
    .circle {
      width: 200px;
    }
  }
}
@include media-breakpoint-down(xs) {
  .actie-banner {
    .circle {
      margin-top: 80px;
      transform: rotate(-25deg);
      width: 175px;
    }
  }
}
