.swiper-container {
  width: 100%;
  height: 100%;
  margin: 40px 0;
}

.swiper {
  width: 100%;
  height: 400px;
}

.swiper-title {
  margin-bottom: 40px;

  .title {
    font-weight: 600;
    font-size: 2rem;
  }
}

.swiper-slide-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;

  .fixed-size-image {
    width: 100%;
    height: 50%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text-content {
    padding: 12px;
    width: 100%;
    height: 100%;
  }

  .index {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #212529;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 1.25rem;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  gap: 40px;
  cursor: pointer;
}
