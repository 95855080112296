.cookie-consent-banner-container {
  z-index: 3000;

  position: fixed;
  bottom: 10px;
  right: 20px;

  .cookie-consent-banner-card {
    padding: 15px 30px;
    background-color: #2d3032;
    color: #fff;
    border-radius: 10px;

    .cookie-consent-banner-title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      font-weight: 501;
      line-height: 1.2;
    }
    p {
      margin-bottom: 0.5rem;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .cookie-checkbox-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .custom-checkbox {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .cookie-consent-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    left: 20px;

    .cookie-consent-banner-card {
      flex-grow: 1;
      padding: 15px 20px;
    }
  }
}

.cookie-consent-banner-animation-enter {
  opacity: 0;
  transform: translateY(100%);
}
.cookie-consent-banner-animation-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.cookie-consent-banner-animation-exit {
  opacity: 1;
  transform: translateY(0);
}
.cookie-consent-banner-animation-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 300ms, transform 300ms;
}
