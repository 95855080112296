#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .site-content {
    flex: 1;
  }
}

.footer {
  color: white;
  background-color: #404446;
  .footer-title {
    color: white;
  }
  .footer-link {
    color: white !important;
    transition: color 0.2s, text-decoration 0.2s;
    padding-left: 0;
    padding-right: 0;
    &:hover {
      color: $primary !important;
      text-decoration: underline;
    }
  }
  padding: 30px;
  margin-top: 40px;
  margin-bottom: 0;
}
.footer-secondary {
  color: white;
  background-color: #2d3032;
  padding: 30px;
  margin: 0;
}

.footer-title {
  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background-color: $primary;
    margin-top: 8px;
  }
}
